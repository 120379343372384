export const NAMES =  {
    'ALL_OK': 'All_OK',
    'BUTTON_CANCEL': 'Button_Cancel',
    'BUTTON_OK': 'Button_OK',
    'FIELD_BG': 'Field_BG',
    'FIELD_BORDER': 'Field_Border',
    'FIELD_BORDER_HIGHLIGHT': 'Field_Border_Highlight',
    'FIELD_LABEL': 'Field_Label',
    'FIELD_PADDING': 'Field_Padding',
    'GLOBAL_SETTING': 'Global_Setting',
    'LEFT_PANEL_BG': 'Left_Panel_BG',
    'LEFT_PANEL_HIGHLIGHT': 'Left_Panel_Highlight',
    'LEFT_PANEL_HIGHLIGHT_FONT': 'Left_Panel_Highlight_Font',
    'MAIN_PANEL_BG': 'Main_Panel_BG',
    'PATIENT_ALERT': 'Patient_Alert',
    'POPUP_HEADER': 'Popup_Header',
    'PROVIDER_ALERT': 'Provider_Alert',
    'SECTION_HEADER': 'Section_Header',
    'STAFF_ALERT': 'Staff_Alert',
    'ADMIN_ALERT': 'Admin_Alert',
    "HEALTH_COACHING_ALERT": "Health_Coaching_Alert",
    "All_OK_Link": "All_OK_Link",
    // user colors for dashboard
    'DEFAULT':'Null_Undefined',
}
